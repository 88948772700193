import * as React from "react"
import Seo from "../../components/seo"
import RewardLinkComponent from "../../components/RewardLinkComponent"
import { StaticImage } from "gatsby-plugin-image"
import "../showcase.css"
import { Link } from "gatsby"
const CardIndex = () => {
  const [rewardToken, setRewardToken] = React.useState(null)

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get("referral")
    if (token) {
      setRewardToken(token)
    }
  }, [])

  return (
    <div>
      <div
        className="parallax"
        style={{
          backgroundImage: `linear-gradient(${"#f0d723"} 1vh,${"orange"})`,
        }}
      />
      <header className="showcase-header">
        <Seo
          title={"Die Artikel spielerisch lernen"}
          lang={"de"}
          canonical={"de/showcase"}
          description={
            "Beeindrucken Sie Ihre Freunde und zeigen Sie, dass Sie wie ein Muttersprachler Deutsch sprechen können."
          }
        />
        <div className="container">
          <div className="main_nav">
            <a
              className="showcase-a mobile-toggle"
              href="https://der-die-das-train.com"
            >
              <span /> <span /> <span />
            </a>
            <nav className="showcase-nav">
              <ul>
                <li>
                  <a className="smoothscroll showcase-a" href="/de/">
                    Startseite
                  </a>
                </li>
                <li>
                  <a className="smoothscroll showcase-a" href="#about">
                    Info
                  </a>
                </li>
                <li>
                  <a className="smoothscroll showcase-a" href="#nouns">
                    Substantive
                  </a>
                </li>
                <li>
                  <a className="smoothscroll showcase-a" href="#gameplay">
                    Gameplay
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div id="first-container" className="row showcase-hero">
            <div className="eight columns">
              <div class="image-container">
                <StaticImage
                  className="bio-avatar"
                  id="wrap-image"
                  layout="fixed"
                  formats={["auto", "webp", "avif"]}
                  src="../images/icon.png"
                  width={100}
                  height={100}
                  quality={95}
                  alt="Application icon"
                />
              </div>
              <h1 className="header">Der Die Das Train</h1>
              <h3 className="subheader">Die Artikel spielerisch lernen</h3>

              {rewardToken ? (
                <RewardLinkComponent
                  prefix={"de"}
                  rewardToken={rewardToken}
                ></RewardLinkComponent>
              ) : (
                <p className="paragraph">
                  Meistere die deutschen Artikel mit unserem aufregenden Spiel!
                  Beeindrucke deine Freunde, während du die Ranglisten erklimmst
                  und ein echter Muttersprachler wirst!
                </p>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "0rem",
                }}
              >
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.der.die.das.train&hl=de&gl=DE"
                >
                  <StaticImage
                    src="../../images/google-play-badge-de-showcase.png"
                    alt="Download on Google Play"
                    style={{ color: "#f67a40" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={60}
                  />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://apps.apple.com/de/app/der-die-das-train-lite/id1562981120#?platform=iphone"
                >
                  <StaticImage
                    src="../../images/apple-store-badge-de-showcase.png"
                    alt="Download on Apple Store"
                    style={{ color: "#f67a40" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={60}
                  />
                </a>
              </div>
            </div>
            <div className="four columns">
              <StaticImage
                src="../../images/mirror/1.png"
                className="phone device showcase-img"
                alt="phone mockup"
                placeholder={"blurred"}
                fit={"contain"}
                height={600}
              />
            </div>
          </div>
        </div>
      </header>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "4rem",
        }}
      >
        <p style={{ margin: "1.5rem", marginTop: "2rem", color: "#525252" }}>
          App jetzt als iOS-Download im App Store und für Android bei Google
          Play verfügbar.
        </p>
      </div>
      <section className="show-section" id="about">
        <div className="container">
          <div className="eight columns">
            <div className="row">
              <h2>Ein Spiel mit deutschen Artikeln</h2>
            </div>
            <div className="row">
              <div className="six columns">
                <p>
                  Der Die Das Train ist ein fesselndes Spiel, das dir hilft,
                  deutsche Substantive und ihre entsprechenden Artikel - ‘der’,
                  ‘die’ und ‘das’ - zu meistern. Lerne und merke dir mit
                  Leichtigkeit während du spielst!
                </p>
                <p>
                  Lerne auf spielerische und unterhaltsame Weise neue deutsche
                  Wörter und ihre Artikel mit unserem zugthemenbezogenen Spiel
                  kennen.
                </p>
              </div>
              <div className="six columns">
                <p>
                  Verabschiede dich von der Frustration, sich an ‘der’, ‘die’
                  und ‘das’ zu erinnern - die Grundlage der deutschen Grammatik
                  ist eine große Herausforderung für jeden Deutschlerner.
                </p>
              </div>
            </div>
          </div>
          <div className="four columns">
            <StaticImage
              src="../../images/mirror/de/2.png"
              className="showcase-img"
              alt="phone mockup"
              placeholder={"blurred"}
              fit={"contain"}
              height={750}
            />
          </div>
        </div>
      </section>
      <section id="nouns" className="show-section">
        <div className="container">
          <div className="row" id="section-heading">
            <h2>Deutsche Substantive und ihre Artikel</h2>
          </div>
          <div className="row">
            <div className="four columns">
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-location-outline showcase-icon" />
                </div>
                <div className="nine columns">
                  <h2>Nomen + Artikel</h2>
                  <p>
                    Im Deutschen hat jedes Substantiv ein Geschlecht und einen
                    entsprechenden Artikel. Es ist wichtig, diese zusammen zu
                    lernen.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-lightbulb showcase-icon" />
                </div>
                <div className="nine columns">
                  <h2>Regeln?</h2>
                  <p>
                    Es gibt allgemeine Regeln oder Gruppierungen von
                    Substantiven, entweder nach Themenbereich oder nach den
                    Buchstaben, auf die sie enden. Wie immer gibt es Ausnahmen
                    von diesen Regeln. Diese Regeln und gegebenenfalls Ausnahmen
                    werden an bestimmten Punkten in das Spiel integriert.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-spanner-outline showcase-icon" />
                </div>
              </div>
            </div>
            <div className="four columns">
              <StaticImage
                src="../../images/mirror/1.png"
                className="phoneFeature showcase-img"
                alt="phone mockup"
                placeholder={"blurred"}
                fit={"contain"}
                height={750}
              />
            </div>
            <div className="four columns">
              <div className="row">
                <div className="nine columns">
                  <h2>Geschlecht + Grammatik</h2>
                  <p>
                    Deutsche Grammatik leicht gemacht mit unserem Spiel! Das
                    Geschlecht und der Artikel eines Substantivs sind der
                    Schlüssel zur Sprache. Unser Spiel macht das Erlernen dieser
                    Grundlage spaßig und fesselnd.
                  </p>
                </div>
                <div className="three columns">
                  <span className="typcn typcn-key-outline showcase-icon" />
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-battery-high showcase-icon" />
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-flash-outline showcase-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="gameplay" className="show-section">
        <div className="container">
          <div className="eight columns">
            <div className="row">
              <h2>Gameplay</h2>
            </div>
            <div className="row">
              <div className="six columns">
                <p>
                  Entdecke neue Wörter und erweitere deinen Wortschatz, während
                  du das U-Bahn-Netz erkundest! Unser System gruppiert Wörter
                  thematisch für eine einfache Merkfähigkeit und schlägt Themen
                  basierend auf konzeptioneller Schwierigkeit, kürzlich
                  besuchten Kategorien und Gedächtnisverlust im Laufe der Zeit
                  vor. Steig ein und beginne heute mit dem Erkunden!
                </p>
                <p>
                  Übernimm die Kontrolle über den Zug mit einem einfachen
                  Fingertipp auf den Bildschirm und führe ihn zum entsprechenden
                  Artikel für jedes Nomen, das oben auf deinem Bildschirm
                  erscheint. Mit In-Game-Übersetzungen (derzeit nur in Englisch
                  verfügbar) war das Erlernen und Erweitern deines Wortschatzes
                  noch nie so einfach oder unterhaltsam!
                </p>
              </div>
              <div className="six columns">
                <p>
                  Verstärke dein Lernen mit jedem Wort, das laut ausgesprochen
                  und von Soundeffekten begleitet wird. Greife einfach auf
                  Wörter, Artikel, Tipps zum Merken von Artikeln, Übersetzungen
                  und Definitionen zu - alles direkt aus dem Spielmenü. Lernen
                  war noch nie so interaktiv und fesselnd!
                </p>
                <p>
                  Spiele stundenlang ohne Langeweile und zeige deine
                  Deutschkenntnisse auf den Bestenlisten. Fordere dich selbst
                  heraus und schau, wie weit du kommen kannst!
                </p>
              </div>
            </div>
          </div>
          <div className="four columns">
            <StaticImage
              src="../../images/mirror/de/3.png"
              className="showcase-img"
              alt="phone mockup"
              placeholder={"blurred"}
              fit={"contain"}
              height={750}
            />
          </div>
        </div>
      </section>
      <section id="leaderboard" className="show-section">
        <div className="container">
          <div className="row" id="section-heading">
            <h2>Ranglisten</h2>
          </div>
          <div className="row">
            <div className="four columns">
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-location-outline showcase-icon" />
                </div>
                <div className="nine columns">
                  <h2>Bleibe motiviert</h2>
                  <p>
                    Sammle Punkte und steige an die Spitze der Ranglisten!
                    Bleibe motiviert und zielstrebig, während du darum kämpfst,
                    der Beste zu sein!
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-lightbulb showcase-icon" />
                </div>
                <div className="nine columns">
                  <h2></h2>
                  <p></p>
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-spanner-outline showcase-icon" />
                </div>
              </div>
            </div>
            <div className="four columns">
              <StaticImage
                src="../../images/mirror/de/4.png"
                className="phoneFeature showcase-img"
                alt="phone mockup"
                placeholder={"blurred"}
                fit={"contain"}
                height={750}
              />
            </div>
            <div className="four columns">
              <div className="row">
                <div className="nine columns">
                  <h2>Streak</h2>
                  <p>
                    Halte deine Streak am Leben, indem du jeden Tag übst!
                    Beständigkeit ist der Schlüssel zum Erfolg und wir sind
                    hier, um dir dabei zu helfen!
                  </p>
                </div>
                <div className="three columns">
                  <span className="typcn typcn-key-outline showcase-icon" />
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-battery-high showcase-icon" />
                </div>
              </div>
              <div className="row">
                <div className="three columns">
                  <span className="typcn typcn-flash-outline showcase-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="gameplay" className="show-section">
        <div className="container">
          <div className="eight columns">
            <div className="row">
              <h2>Erst lernen, dann spielen</h2>
            </div>
            <div className="row">
              <div className="six columns">
                <p>
                  Bist du neu beim Deutschlernen und fühlst dich überfordert?
                  Keine Sorge! Unser Lernmodus ermöglicht es dir, die Artikel
                  und Wörter in deinem eigenen Tempo zu meistern. Und wenn du
                  auf ein unbekanntes Wort stößt, kannst du ganz einfach seine
                  Definition aufrufen.
                </p>
                <p></p>
              </div>
              <div className="six columns">
                <p></p>
                <p></p>
              </div>
            </div>
          </div>
          <div className="four columns">
            <StaticImage
              src="../../images/mirror/de/6.png"
              className="showcase-img"
              alt="phone mockup"
              placeholder={"blurred"}
              fit={"contain"}
              height={750}
            />
          </div>
        </div>
      </section>

      <section id="gameplay" className="show-section">
        <div className="container">
          <div className="four columns">
            <StaticImage
              src="../../images/mirror/de/5.png"
              className="showcase-img"
              alt="phone mockup"
              placeholder={"blurred"}
              fit={"contain"}
              height={750}
            />
          </div>
          <div className="eight columns">
            <div className="row">
              <h2>Wörterbuch</h2>
            </div>
            <div className="row">
              <div className="six columns">
                <p>
                Bist du in der Deutschklasse oder beim Selbstlernen auf ein unbekanntes Wort gestoßen? Suche es einfach und entdecke seine Definition, Übersetzung, Beispielsätze, Artikel und Deklination. Füge es außerdem zu deinen Favoriten hinzu, um es später leichter zu überprüfen. Lass dich nie wieder von einem neuen Wort überraschen!
                </p>
                <p>

                </p>
              </div>
              <div className="six columns">
                <p>

                </p>
                <p>

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="wordlist" className="show-section">
        <div className="container">
          <div className="row" id="section-heading">
            <h4 style={{ textAlign: "center" }}>
              Laden Sie jetzt die App herunter und spielen Sie noch heute. Sie
              werden sich selbst überraschen, wie schnell und einfach Sie die
              Artikel erlernen.
            </h4>
          </div>
        </div>
      </section>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginBottom: "3rem",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "0rem" }}
        >
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.der.die.das.train&hl=de&gl=DE"
          >
            <StaticImage
              src="../../images/google-play-badge-de-showcase.png"
              alt="Download on Google Play"
              style={{ color: "#f67a40" }}
              placeholder={"blurred"}
              fit={"contain"}
              height={60}
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://apps.apple.com/de/app/der-die-das-train-lite/id1562981120#?platform=iphone"
          >
            <StaticImage
              src="../../images/apple-store-badge-de-showcase.png"
              alt="Download on Apple Store"
              style={{ color: "#f67a40" }}
              placeholder={"blurred"}
              fit={"contain"}
              height={60}
            />
          </a>
        </div>
      </div>
      <footer className="showcase-footer">
        <section id="languages">
          <div className="container">
            <div className="row" id="section-heading">
              <h2>Kostenlos verfügbar?</h2>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <strong>Werbung</strong>
              <p style={{ color: "gray", textAlign: "left" }}>
              Die meisten unserer Inhalte sind kostenlos und für alle Benutzer zugänglich. Dieses Lernwerkzeug wird jedoch durch Werbung unterstützt und Anzeigen werden innerhalb der App erscheinen. Um Premium-Funktionen freizuschalten, schaue einfach eine kurze Anzeige an und genieße eine ganze Woche lang Zugang zu exklusiven Funktionen und keine weiteren Anzeigen. Wir haben unsere Anzeigen sorgfältig ausgewählt, um die Störung deines Lernerlebnisses so gering wie möglich zu halten.
              </p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <strong></strong>
              <p style={{ color: "gray", textAlign: "left" }}>
              </p>
            </div>

            <section>
              <Link to={"/privacy-policy"}>Privacy Policy</Link> |{" "}
              <Link to={"/terms-and-conditions/"}>Terms and Conditions</Link>
              <span>
                {` `}© {new Date().getFullYear()}
                {` `}
              </span>
              <span style={{ fontWeight: "bold" }} href="https://mapley.dev">
              Der Die Das Train
              </span>
            </section>
          </div>
        </section>
      </footer>
    </div>
  )
}
export default CardIndex
